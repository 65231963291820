import { render, staticRenderFns } from "./employee.html?vue&type=template&id=ef63d0b0&scoped=true&"
import script from "./employee.js?vue&type=script&lang=js&"
export * from "./employee.js?vue&type=script&lang=js&"
import style0 from "./employee.scss?vue&type=style&index=0&id=ef63d0b0&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef63d0b0",
  null
  
)

export default component.exports