import { mapGetters } from "vuex";
import * as types from "../../../../store/types";
import rolesService from "../../../../services/rolesService";
import $renderDate from "./../../../../mixins/renderDate";

export default {
  name: 'employee',
  components: {},
  props: [],
  mixins: [$renderDate],
  data() {
    return {
      employee: "",
      roles: "",
      isrole: false,
      tableHeaders: [
        "نام و نام خانوادگی",
        "نقش(ها)",
        "نام کاربری",
        "مدیریت",

      ],
      employeeFullName: null,
      employeeUserName: null,
    }
  },
  computed: {
    ...mapGetters({
      userInfo: types.GET_USER_INFO,
    }),
  },
  mounted() {
    this.getAllEmployee();
    this.getRoles();
  },
  methods: {
    // Show Users Role
    getAllEmployee() {
      rolesService.getAllEmployee(
        null,
        null,
        this.userInfo.merchantId
      ).then((Response) => {
        this.employee = Response.data;
      });
    },
    // serach Employee
    searchEmployee() {
      if (this.employeeFullName !== "" || this.employeeUserName !== "") {
        rolesService.getAllEmployee(
          this.employeeFullName,
          this.employeeUserName,
          this.userInfo.merchantId
        ).then((Response) => {
          this.employee = Response.data;
        });
      }

    },
    //remove role
    remove(id, role) {
      rolesService.employeeRolesRemove(
        id,
        role,
        this.userInfo.merchantId
      ).then((Response) => {
        if (Response.data) {
          this.getAllEmployee();
        }

      }).catch((Response) => {
        if (Response.data) {
          this.$bvToast.toast(Response.data.message, {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass:
              "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false,
          });
        }
      });

    },
    //add role
    add(id, role) {
      rolesService.employeeRolesAdd(
        id,
        role,
        this.userInfo.merchantId
      ).then((Response) => {
        if (Response.data) {
          this.getAllEmployee();
        }

      }).catch((Response) => {
        if (Response.data) {
          this.$bvToast.toast(Response.data.message, {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass:
              "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false,
          });
        }
      });

    },
    // Get Roles
    getRoles() {
      rolesService.getRoles().then((Response) => {
        this.roles = Response.data;
      });

    },
    //Check Roles
    checkIsRole(employee, roleName) {
      for (let i = 0; i < employee.roles.length; i++) {
        if (employee.roles[i].role === roleName) return true;
      }
      return false;
    }
  }
}


